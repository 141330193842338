body {
  margin: 0;
  padding: 0;
  font-family: 'Varela Round';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.App {
  text-align: center;
  background-color: #282c34;
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  padding: 50px;
  box-sizing: border-box;
}
.App header {
  width: 100%;
  height: 20%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  text-transform: uppercase;
}
.App header .date {
  width: 80px;
  height: 50px;
  display: -webkit-flex;
  display: flex;
}
.App header .date .day-number {
  width: 50%;
  height: 100%;
  line-height: 50px;
  font-size: 50px;
}
.App header .date .month-year {
  width: 50%;
  height: 100%;
  font-size: 20px;
  margin-left: 30px;
}
.App header .date .month-year .month,
.App header .date .month-year .year {
  text-align: left;
}
.App header .date .month-year .year {
  opacity: 0.8;
}
.App header .day {
  height: 50px;
  line-height: 50px;
}
.App .todo-list {
  width: 100%;
  height: 80%;
  overflow-y: scroll;
}
.App .todo-list .todo-item {
  width: 100%;
  height: 50px;
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
}
.App .todo-list .todo-item input {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 100%;
  background: none;
  border: none;
  color: #fff;
  font-size: 1.1em;
  padding: 0;
}
.App .todo-list .todo-item div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.App .todo-list .todo-item .task {
  width: 80%;
  height: 100%;
  padding: 0px 20px;
  transition: 0.3s opacity;
}
.App .todo-list .todo-item .task.done {
  opacity: 0.5;
}
.App .todo-list .todo-item .checkbox-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 20%;
  height: 100%;
}
.App .todo-list .todo-item .checkbox-container svg .back-circle {
  fill: none;
  stroke: #929599;
  stroke-width: 2px;
}
.App .todo-list .todo-item .checkbox-container svg .done-circle {
  fill: none;
  stroke: #51e3a4;
  stroke-width: 2px;
  stroke-dasharray: 225px;
  stroke-dashoffset: 225px;
  transition: 0.75s stroke-dashoffset cubic-bezier(0.75, 0, 0.25, 1);
}
.App .todo-list .todo-item .checkbox-container svg .done-circle.done {
  stroke-dashoffset: 80px;
}
.App .todo-list .todo-item .delete {
  font-size: 1.5em;
}
.App .todo-list .todo-item .delete span {
  display: inline-block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.App .open-add-todo {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #51e3a4;
  text-align: center;
  font-size: 3em;
  z-index: 2;
  cursor: pointer;
}
.App .open-add-todo span {
  display: inline-block;
  transition: 0.5s -webkit-transform cubic-bezier(0.75, 0, 0.25, 1);
  transition: 0.5s transform cubic-bezier(0.75, 0, 0.25, 1);
  transition: 0.5s transform cubic-bezier(0.75, 0, 0.25, 1), 0.5s -webkit-transform cubic-bezier(0.75, 0, 0.25, 1);
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.App .open-add-todo.open span {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
.App .new-todo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  z-index: -1;
  transition: 0.75s z-index 0.75s;
}
.App .new-todo .scale {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #51e3a4;
  transition: 0.75s -webkit-transform cubic-bezier(0.75, 0, 0.25, 1);
  transition: 0.75s transform cubic-bezier(0.75, 0, 0.25, 1);
  transition: 0.75s transform cubic-bezier(0.75, 0, 0.25, 1), 0.75s -webkit-transform cubic-bezier(0.75, 0, 0.25, 1);
  z-index: 0;
}
.App .new-todo .add {
  position: relative;
  width: 60%;
  height: 80px;
  margin-top: 50vh;
  -webkit-transform: translateY(calc(-50% - 50px));
          transform: translateY(calc(-50% - 50px));
  opacity: 0;
  transition: 0.3s opacity cubic-bezier(0.75, 0, 0.25, 1);
}
.App .new-todo .add input {
  width: 100%;
  height: 50%;
  border-radius: 50px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 1.1em;
  border: none;
}
.App .new-todo .add button {
  width: 100px;
  height: 50%;
  border-radius: 50px;
  border: none;
  background-color: #47c791;
  color: #fff;
  text-transform: uppercase;
  margin-top: 30px;
  font-size: 1.1em;
}
.App .new-todo.open {
  z-index: 1;
  transition: none;
}
.App .new-todo.open .scale {
  -webkit-transform: translate(-50%, 50%) scale(25);
          transform: translate(-50%, 50%) scale(25);
}
.App .new-todo.open .add {
  opacity: 1;
  transition: 0.5s opacity cubic-bezier(0.75, 0, 0.25, 1) 0.5s;
}

